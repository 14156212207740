import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'

const NotFoundPage = () => (
   <Layout>
      <SEO title="404: Resource Not Found" />
      <div
         style={{
            padding: `3rem`,
            margin: `12.5rem 5rem 5rem`,
            backgroundColor: `#f3f0e7`,
         }}>
         <h1 style={{ margin: `0` }}>Resource Not Found</h1>
         <p style={{ margin: `0` }}>
            Sorry, you just hit a route that doesn&#39;t exist. If you think
            this is an error,{' '}
            <Link to="/contact" style={{ borderBottom: `3px solid #1C4489` }}>
               contact us
            </Link>
            .
         </p>
         <Link
            to="/"
            className="button button--primary button--has-icon button--has-icon_left"
            style={{ marginTop: `2rem` }}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Home?
         </Link>
      </div>
   </Layout>
)

export default NotFoundPage
